.Benefit__main-contiainer{
    /* border: 6px solid red; */
    position: relative;
}   
    .Benefit__inner-contianer{
        /* border: 3px solid rgb(0, 0, 0); */
        display: flex;
        margin-top: 4em;
    }

    /*======== BACKGROUND MEDIA  ============*/
    .Benefit__background-design-1{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 100%;
        right: 0;
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        z-index: -1;
    }
       

    .Benefit__background-design-2{
        /* border: 3px solid green; */
        width: 100%;
        right: 0;
        left: 0;
        top: 33%;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: -1;
    }
    .Benefit__background-design-3{
        /* border: 3px solid blue; */
        width: 100%;
        right: 0;
        left: 0;
        top: 50%;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        z-index: -1;
    }
    .Benefit__background-design-4{
        /* border: 3px solid black; */
        width: 100%;
        right: 0;
        left: 0;
        bottom: 10%;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: -1;
    }
    .Benefit__background-design-5{
        /* border: 3px solid red; */
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        z-index: -1;
    }

    .Benefit__desing1, .Benefit__desing2, .Benefit__desing, .Benefit__desing4, .Benefit__desing5{
        width: 100%;
    }

/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Benefit__desing1, .Benefit__desing2, .Benefit__desing3, .Benefit__desing4, .Benefit__desing5{
        opacity: .1;
    }


    
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    /* .Benefit__desing1, .Benefit__desing2, .Benefit__desing3, .Benefit__desing4, .Benefit__desing5{
        opacity: .2;
    } */
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 6em;
        padding: 0;
    }
   
    /*   */
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 6em;
    }
    /* .Benefit__desing1, .Benefit__desing2, .Benefit__desing3, .Benefit__desing4, .Benefit__desing5{
        opacity: .2;
    } */
}

@media (min-width: 1200px ) and ( max-width: 1499.98px) {
    .Benefit__main-contiainer{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6em;
        padding: 0;

    }   
        .Benefit__inner-contianer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            /* border: 3px solid green; */
        }

        /* .Benefit__desing1, .Benefit__desing2, .Benefit__desing3, .Benefit__desing4, .Benefit__desing5{
            opacity: .2;
        } */
        .Benefit__desing1, .Benefit__desing2, .Benefit__desing4, .Benefit__desing5{
            width: 70%;
        }
}

@media (min-width: 1500px){
    .Benefit__main-contiainer{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;

    }   
        .Benefit__inner-contianer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 90em;
        }
        /* .Benefit__desing1, .Benefit__desing2, .Benefit__desing3, .Benefit__desing4, .Benefit__desing5{
            opacity: .2;
        } */
        .Benefit__desing1, .Benefit__desing2, .Benefit__desing4, .Benefit__desing5{
            width: 100%;
        }
}

@media (min-width: 1900px){
    .Benefit__main-contiainer{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;

    }   
        .Benefit__inner-contianer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 100em;
        }
        /* .Benefit__desing1, .Benefit__desing2, .Benefit__desing3, .Benefit__desing4, .Benefit__desing5{
            opacity: .2;
        } */
}