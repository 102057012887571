.BenefitCard__main-container{
    /* border: 5px solid blue; */
    /* max-width: 28em; */
    border-radius: 1.25rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 2rem;
    width: 40.625rem;
padding: 1.25rem 0.3125rem;
    max-height: 100%;
    flex-direction: column;
    justify-content: center;
 
    gap: 1.25rem;
}
    .BenefitCard__inner-container{
        /* border: 3px solid saddlebrown; */
    }
        .BenefitCard__header-container{
            /* border: 8px solid green; */
            width: 100%;
        }
            .BenefitCard__media-container{
                /* border: 3px solid rgb(0, 0, 0); */
            }
                .BenefitCard__icon-container{
                    /* border: 3px solid blue; */
                }
                .BenefitCard__description-container{
                    /* border: 3px solid red; */
                }   

.BenefitCard__divider{
    margin-top: .5em;
    border: 2px solid var(--secundary-color);
}

/* CARD TITLE*/
.BenefitCard__description-container div p{
    color: rgb(0, 0, 0);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.BenefitCard__information p{
    color: #00030A;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.25rem;
}
.BenefitCard__about-container{
    margin-left: .5em;
    
}








/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
    .BenefitCard__main-container{
        max-width: 20em;
        border-radius: 1.25rem;
        background: none;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: .5em;
    }    
    .BenefitCard__inner-container{
        padding: 1em;
    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .BenefitCard__about-information{
        padding: 1em;
    }
    .BenefitCard__information p {
        text-align: justify;
        font-size: 1.3rem;
        line-height: 1.9rem;
    }

    .BenefitCard__description-container{
        margin-top: .5em;
        margin-bottom: .5em;
    }
    .BenefitCard__description-container div p{
        font-size: 1.5rem;
        padding-left: .8em;
    }

}


@media (min-width: 576px) and (max-width: 767.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 35em;
        height: auto;
        border-radius: 1.25rem;
        /* background: var(--primary-color); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* padding: .5em; */
        padding-left: .5em;
        padding-right: .5em;
    }    
    .BenefitCard__inner-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        height: auto;
   

    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-information{
        padding: .3em;
        /* border: 3px solid rgb(8, 245, 60); */
    }
    .BenefitCard__information p {
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: 500;
    }

    .BenefitCard__description-container div p{
        font-size: 1.3rem;
        /* padding-left: .8em; */
    }


    .BenefitCard__header-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    }
    .BenefitCard__media-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 10em;
    }
    .BenefitCard__about-container{
        /* border: 3px solid red; */

    }

    .BenefitCard__icon-container{
        width: 6em;
    }
        .BenefitCard__icon{
            width: 100%;
        }
 
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 22em;
        height: auto;
        border-radius: 1.25rem;
        /* background: var(--primary-color); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* padding: .5em; */
        padding-left: .5em;
        padding-right: .5em;
        margin-left: 1em;
        margin-right: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }    
    .BenefitCard__inner-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        height: auto;
   

    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-information{
        padding: .3em;
        /* border: 3px solid rgb(8, 245, 60); */
    }
    .BenefitCard__information p {
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: 500;
    }

    .BenefitCard__description-container div p{
        font-size: 1.3rem;
        padding-left: .2em;
    }


    .BenefitCard__header-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    }
    .BenefitCard__media-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 10em;
    }
    .BenefitCard__about-container{
        /* border: 3px solid red; */

    }

    .BenefitCard__icon-container{
        width: 6em;
    }
        .BenefitCard__icon{
            width: 100%;
        }
 
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 26em;
        height: auto;
        border-radius: 1.25rem;
        /* background: var(--primary-color); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* padding: .5em; */
        padding-left: .5em;
        padding-right: .5em;
        margin-left: 1em;
        margin-right: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }    
    .BenefitCard__inner-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        height: auto;
   

    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
     
    }

    .BenefitCard__about-information{
        padding: .3em;
    
    }
    .BenefitCard__information p {
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: 500;
    }

    .BenefitCard__description-container div p{
        font-size: 1.3rem;
        padding-left: .3em;
    }


    .BenefitCard__header-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    }
    .BenefitCard__media-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 10em;
    }
   

    .BenefitCard__icon-container{
        width: 6em;
    }
        .BenefitCard__icon{
            width: 100%;
        }
 
}

@media (min-width: 1200px) {
    .BenefitCard__main-container{
        /* border: 5px solid blue; */
        max-width: 35em;
        height: auto;
        border-radius: 1.25rem;
        /* background: var(--primary-color); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* padding: .5em; */
        padding-left: .5em;
        padding-right: .5em;
        margin-right: 1em;
        margin-left: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }    
    .BenefitCard__inner-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        height: auto;
   

    }
    .BenefitCard__media-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .BenefitCard__description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 5px solid rgb(0, 0, 0); */
    }

    .BenefitCard__about-information{
        padding: .3em;
        /* border: 3px solid rgb(8, 245, 60); */
    }
    .BenefitCard__information p {
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: 500;
    }

    .BenefitCard__description-container div p{
        font-size: 1.3rem;
        /* padding-left: .8em; */
    }


    .BenefitCard__header-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
    }
    .BenefitCard__media-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 10em;
    }
    .BenefitCard__about-container{
        /* border: 3px solid red; */

    }

    .BenefitCard__icon-container{
        width: 6em;
    }
        .BenefitCard__icon{
            width: 100%;
        }
 
}
