.SubscriptionPlan__subscription-plans-container {
    /* border: 3px solid red; */
}

.SubscriptionPlan__subscription-description-component-container {
    /* border: 3px solid blue; */
}

.SubscriptionPlan__subscription-card-component-container {
    /* border: 3px solid purple; */
}

.SubscriptionPlan__contact-card {
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.SubscriptionPlan__main-title{
    margin-top: 1.5em;
}
.SubscriptionPlan__main-title h1{
    color: #000;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.05rem;
    /* 26.25% */
}

.SubscriptionPlan__background-design-1 {
    /* border: 3px solid red; */
    width: 100%;
    right: 0;
    left: 0;
    top: 5%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: -1;
}

.SubscriptionPlan__background-design-2 {
    /* border: 3px solid rgb(0, 0, 0); */
    width: 100%;
    right: 0;
    left: 0;
    bottom: 10%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: -1;
}

.SubscriptionPlan__background-design-3 {
    /* border: 3px solid rgb(0, 255, 174); */
    width: 100%;
    right: 0;
    left: 0;
    bottom: 35%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: -1;
}



@media (max-width: 575.98px) {

    .SubscriptionPlan__main-section-container {
        padding: 1.3em;

    }

    .SubscriptionPlan__subscription-plans-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .SubscriptionPlan__title {
        line-height: 2.5rem;
    }

    .SubscriptionPlan__subscription-description-component-container {
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SubscriptionPlan__subscription-card-component-container {
        margin-top: .5em;
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    /* .SubscriptionPlan__design1, .SubscriptionPlan__design2,.SubscriptionPlan__design3{
        opacity: .2;
    } */
    .SubscriptionPlan__main-title h1{
        line-height: 3.5rem;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionPlan__main-section-container {
        padding: 1.3em;
    }

    .SubscriptionPlan__subscription-plans-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .SubscriptionPlan__title {
        line-height: 2.5rem;
    }


    .SubscriptionPlan__subscription-description-component-container {
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SubscriptionPlan__subscription-card-component-container {
        margin-top: .5em;
        /* border: 3px solid purple; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    /* .SubscriptionPlan__design1, .SubscriptionPlan__design2,.SubscriptionPlan__design3{
        opacity: .1;
    } */
    .SubscriptionPlan__main-title h1{
        line-height: 3.5rem;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionPlan__main-section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }

    .SubscriptionPlan__subscription-plans-container {
        flex-direction: row;
        justify-content: center;
        /* margin-left: 1.8em; */
        padding: 1em;
        /* border: 3px solid rgb(0, 0, 0); */
    }

    .SubscriptionPlan__main-section-container {
        /* border: 3px solid rgb(255, 0, 0); */
        display: flex;
    }

    .SubscriptionPlan__subscription-plans-container {
        /* border: 5px solid blue; */
        display: flex;
        flex-direction: row;
    }

    .SubscriptionPlan__subscription-description-component-container {
        /* border: 3px solid yellow; */
        width: 60%;
        padding-left: 1em;
        padding-right: 1em;
    }

    .SubscriptionPlan__subscription-card-component-container {

        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0;
    }
   
    /* .SubscriptionPlan__design1, .SubscriptionPlan__design2,.SubscriptionPlan__design3{
        opacity: .1;
    } */
    .SubscriptionPlan__main-title h1{
        line-height: 3.5rem;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionPlan__main-section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }

    .SubscriptionPlan__subscription-plans-container {
        flex-direction: row;
        justify-content: center;
        margin-left: 1.8em;
        padding: 1em;
        /* border: 3px solid rgb(255, 0, 0); */
    }

    .SubscriptionPlan__main-section-container {
        /* border: 3px solid green; */
        padding-left: 1em;
        padding-right: 1em;
    }


    .SubscriptionPlan__subscription-plans-container {
        /* border: 5px solid blue; */
        display: flex;
        flex-direction: row;
    }

    .SubscriptionPlan__subscription-description-component-container {
        /* border: 3px solid yellow; */
        width: 60%;
        padding-left: 1em;
        padding-right: 2em;
    }

    .SubscriptionPlan__subscription-card-component-container {
        /* border: 3px solid rgb(0, 0, 0); */
        width: 100%;
        padding: .3em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    /* .SubscriptionPlan__design1, .SubscriptionPlan__design2,.SubscriptionPlan__design3{
        opacity: .1;
    } */
    .SubscriptionPlan__main-title h1{
        line-height: 3.5rem;
    }
}

@media (min-width: 1200px) {
    .SubscriptionPlan__subscription-plans-container {
        flex-direction: row;
        justify-content: center;
        align-content: center;
        padding: 6em;
    }

    .SubscriptionPlan__main-section-container {
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SubscriptionPlan__subscription-plans-container {
        /* border: 5px solid blue; */
        display: flex;
        flex-direction: row;
    }

    .SubscriptionPlan__subscription-description-component-container {
        /* border: 3px solid yellow; */
        max-width: 60%;
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .SubscriptionPlan__subscription-card-component-container {
        /* border: 3px solid rgb(0, 0, 0); */
        max-width: 100%;
        padding: .3em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

}