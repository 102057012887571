.faqs {
  width: 100%;
  margin: 0 auto;
  padding: 15px;

}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
  background-color: rgb(255, 255, 255);
  color: var(--secundary-color);
  font-family: Lato;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
  padding-left: 2em;
  cursor: pointer;
}

.faqs .faq .faq-question::after {
  content: "+";
  font-size: 3rem;
  position: absolute;
  top: 40%;
  left: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
  cursor: pointer;
  color: black;
  /* border: 3px solid red; */
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  background-color: rgb(255, 255, 255);
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
  font-size: 2rem;
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    border-radius: 10px;
    /* background: #e40e0e; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 15em;
  }

  .faq-question {
    font-size: 1.5rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2em !important;
  }


}